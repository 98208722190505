<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <button
        @click="handleSaveBtn()"
        class="bg-[#049758] hover:bg-green-700 text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
      >
        <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
        <p>Шинэ данс нэмэх</p>
      </button>

      <ModalCom :isOpen="isModalOpen" :title="title" @close="isModalOpen = false" @save="saveNewAccount" :message="message">
        <div class="grid grid-cols-2 gap-3">
          <!-- 1 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-code" class="text-grayFont ml-1">Дансны код</label>
            <input type="text" id="account-code" class="border p-1.5 rounded-sm" v-model="isValue.accountCode" :disabled="title == 'Данс засах'" />
          </div>

          <!-- 2 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-name" class="text-grayFont ml-1">Дансны нэр</label>
            <input type="text" id="account-name" class="border p-1.5 rounded-sm" v-model="isValue.accountName" />
          </div>

          <!-- 3 Input with Label -->
          <div class="flex flex-col gap-1 relative">
            <label for="account-category" class="text-grayFont ml-1">Дансны ангилал</label>
            <div class="relative">
              <input
                type="text"
                id="account-category"
                class="border p-1.5 pl-4 rounded-sm w-full flex items-center cursor-pointer"
                v-model="isValue.accountCategory"
              />
              <font-awesome-icon
                icon="fa-chevron-down"
                class="w-2 h-2 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                @click="isDropdownOpen = true"
              />
            </div>

            <!-- Dropdown Options -->
            <div v-if="isDropdownOpen" class="absolute mt-1 w-full bg-white border rounded-sm shadow-lg">
              <ul class="py-1">
                <li
                  v-for="option in categoryList"
                  :key="option.account_category"
                  @click="selectOption(option)"
                  class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  {{ option.account_category }}
                </li>
              </ul>
            </div>
          </div>

          <!-- 4 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-desc" class="text-grayFont ml-1">Дансны тайлбар</label>
            <input type="text" id="account-desc" class="border p-1.5 rounded-sm" v-model="isValue.accountDescription" />
          </div>

          <!-- 5 Option with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-status" class="text-grayFont ml-1">Дансны төрөл</label>
            <select id="account-status" class="border p-1.5 rounded-sm" v-model="isValue.accountType">
              <option disabled selected>Төрөл сонгох</option>
              <option value="Debit">Debit</option>
              <option value="Credit">Credit</option>
            </select>
          </div>

          <!-- 6 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="transaction_amount" class="text-grayFont ml-1">Эхний үлдэгдэл</label>
            <input type="text" id="transaction_amount" class="border p-1.5 rounded-sm" v-model="formattedAmount" />
          </div>
        </div>
      </ModalCom>

      <h1 class="text-base my-4 font-bold">
        Нийт
        <span>({{ data?.totalRecords }})</span>
      </h1>

      <div>
        <div v-if="data?.list?.length > 0">
          <hot-table :data="data.list" :settings="hotSettings" ref="hotTableRef" class="custom-handsontable m-3"></hot-table>
          <PaginationCom class="mt-6" @data-incomplete="handlePage" :totalListPages="data.pages"></PaginationCom>
        </div>

        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, reactive, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'

  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'
  import ModalCom from '@/components/bowsys/ModalCom.vue'
  import PaginationCom from '@/components/ui/PaginationCom'

  registerAllModules()

  export default {
    name: 'ChartOfAccounts',
    components: { BowsysNavbar, HotTable, SpinnerCom, ModalCom, PaginationCom },
    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const hotTableRef = ref(null)
      const categoryList = ref([])

      const isModalOpen = ref(false)
      const isDropdownOpen = ref(false)
      const title = ref('Шинэ данс үүсгэх')

      const message = reactive({
        text: '',
        isVisible: false,
      })

      /*
       **************************************** ADD NEW ACCOUNT ****************************************
       */

      const isValue = reactive({
        accountCode: '',
        accountCategory: '',
        accountName: '',
        accountDescription: '',
        accountType: '',
        transactionAmount: '',
      })

      // const formattedAmount = computed({
      //   get() {
      //     return isValue.transactionAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      //   },
      //   set(value) {
      //     isValue.transactionAmount = value.replace(/,/g, '')
      //   },
      // })

      // const formattedAmount = computed({
      //   get() {
      //     return isValue.transactionAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      //   },
      //   set(value) {
      //     isValue.transactionAmount = parseFloat(value.replace(/,/g, ''))
      //   },
      // })

      const formattedAmount = computed({
        get() {
          const amount = isValue.transactionAmount
          return amount !== null && amount !== undefined && amount !== '' ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
        },
        set(value) {
          if (value === '') {
            isValue.transactionAmount = ''
          } else {
            const numericValue = parseFloat(value.replace(/,/g, ''))
            isValue.transactionAmount = !isNaN(numericValue) ? numericValue : ''
          }
        },
      })

      const resetValues = () => {
        Object.assign(isValue, {
          accountCode: '',
          accountCategory: '',
          accountName: '',
          accountDescription: '',
          accountType: '',
          transactionAmount: '',
        })
      }

      const handleSaveBtn = () => {
        isModalOpen.value = true
        title.value = 'Шинэ данс үүсгэх'
        resetValues()
      }

      const selectOption = (option) => {
        isValue.accountCategory = option.account_category
        isDropdownOpen.value = false
      }

      const saveNewAccount = async () => {
        if (title.value == 'Данс засах') {
          await insertUpdateDeleteAccount({ ...isValue, type: 'update' })
        } else {
          await insertUpdateDeleteAccount(isValue)
        }
      }

      /*
       **************************************** SHOW ACCOUNT LIST ****************************************
       */

      const accountTypeIcons = {
        Хөрөнгө: {
          imgUrl: 'https://cdn-icons-png.flaticon.com/128/10841/10841365.png',
          imgBackColor: '#3DD5A2',
        },
        'Өр төлбөр': {
          imgUrl: 'https://cdn-icons-png.flaticon.com/128/2415/2415223.png',
          imgBackColor: '#E2A1C7',
        },
        'Эздийн өмч': {
          imgUrl: 'https://cdn-icons-png.flaticon.com/128/8910/8910717.png',
          imgBackColor: '#42B2FA',
        },
        Зардал: {
          imgUrl: 'https://cdn-icons-png.flaticon.com/128/1570/1570887.png',
          imgBackColor: '#DFA74F',
        },
        Орлого: {
          imgUrl: 'https://cdn-icons-png.flaticon.com/128/5412/5412778.png',
          imgBackColor: '#C8F198',
        },
      }

      const safeHtmlRenderer = (instance, td, row, col, prop, value) => {
        const rowData = instance.getDataAtRowProp(row, 'account_category')
        const iconData = accountTypeIcons[rowData] || {}
        const iconUrl = iconData.imgUrl || ''
        const imageBackgroundColor = iconData.imgBackColor || '#FFF'

        td.innerHTML = value

        if (iconUrl) {
          if (col === 0) {
            td.innerHTML = `<div class="relative flex items-center justify-center py-2">
          <img src="${iconUrl}" alt="" class="h-7 w-7 object-fit p-1 rounded-lg" style="background-color: ${imageBackgroundColor};" />
        </div>`
          }
        }

        // const displayValue = value !== null && value !== undefined ? value : ''

        // if (col == 2 || col == 4 || col == 6) {
        //   const inputWidth = col === 4 ? 'w-11/12' : 'w-1/2'
        //   const formattedValue = !isNaN(parseFloat(displayValue)) ? parseInt(displayValue).toLocaleString() : displayValue
        //   td.innerHTML = `<input type="text" value="${formattedValue}" class="border border-[#ccc] p-0.5 text-center rounded-sm ${inputWidth}"/>`

        //   const inputElement = td.querySelector('input')

        //   inputElement.addEventListener('blur', () => {
        //     const newValue = inputElement.value

        //     instance.setDataAtCell(row, col, newValue)
        //   })
        // } else if (col == 3) {
        //   let optionsHtml = categoryList.value
        //     .map(
        //       (option) =>
        //         `<option value="${option.account_category}" ${option.account_category === value ? 'selected' : ''}>${
        //           option.account_category
        //         }</option>`
        //     )
        //     .join('')

        //   td.innerHTML = `<select class="border border-[#ccc] p-1 text-center rounded-sm w-4/5">${optionsHtml}</select>`

        //   const selectElement = td.querySelector('select')
        //   selectElement.addEventListener('change', () => {
        //     const newValue = selectElement.value
        //     instance.setDataAtCell(row, col, newValue)
        //   })
        // } else if (col == 5) {
        //   let optionsHtml = ['debit', 'credit']
        //     .map((option) => `<option value="${option}" ${option === value ? 'selected' : ''}>${option}</option>`)
        //     .join('')

        //   td.innerHTML = `<select class="border border-[#ccc] p-1 text-center rounded-sm w-4/5">${optionsHtml}</select>`

        //   const selectElement = td.querySelector('select')
        //   selectElement.addEventListener('change', () => {
        //     const newValue = selectElement.value
        //     instance.setDataAtCell(row, col, newValue)
        //   })
        // }

        // if (col == 7) {
        //   td.innerHTML = `<div class="flex items-center justify-center h-full">
        //       <label class="relative inline-flex items-center cursor-pointer">
        //         <input type="checkbox" class="sr-only peer" ${value === 'active' ? 'checked' : ''}>
        //         <div class="w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-500 rounded-full peer peer-checked:bg-blue-600"></div>
        //         <div class="absolute left-0.5 top-0.5 bg-white border-gray-300 border rounded-full h-2.5 w-2.5 transition-transform peer-checked:translate-x-4"></div>
        //       </label></div>`

        //   const checkbox = td.querySelector('input[type="checkbox"]')

        //   checkbox.addEventListener('change', async () => {
        //     const newValue = checkbox.checked ? 'active' : 'inactive'
        //     instance.setDataAtCell(row, col, newValue)

        //     console.log('first', newValue)
        //     // Optionally: Trigger an action or dispatch a store event
        //     // await store.dispatch('updateAccountStatus', { row, newValue });
        //   })
        // }

        if (col === 6) {
          const numericValue = parseFloat(value)

          if (value === null || value === '' || isNaN(numericValue) || numericValue === 0) {
            td.innerHTML = '-'
          } else if (numericValue > 0) {
            const isWholeNumber = numericValue % 1 === 0

            const formattedValue = isWholeNumber
              ? numericValue.toLocaleString(undefined)
              : numericValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })

            td.innerHTML = formattedValue
          }
        } else if (col === 7) {
          td.innerHTML = `<div class="flex items-center justify-center gap-5">
            <button class="update"><img src="https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
            <button class="delete"><img src="https://cdn.pixabay.com/photo/2014/03/25/15/19/cross-296507_1280.png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
          </div>`
          const deleteButton = td.querySelector('.delete')
          const updateButton = td.querySelector('.update')
          const rowData = instance.getDataAtRow(row)

          if (updateButton) {
            updateButton.addEventListener('click', async () => {
              isModalOpen.value = true
              title.value = 'Данс засах'

              isValue.accountCode = rowData[1]
              isValue.accountCategory = rowData[3]
              isValue.accountName = rowData[2]
              isValue.accountDescription = rowData[4]
              isValue.accountType = rowData[5]
              isValue.transactionAmount = rowData[6]
            })
          }

          if (deleteButton) {
            deleteButton.addEventListener('click', async () => {
              const confirmation = confirm(`Та ${rowData[1]} кодтой ажлыг устгахдаа итгэлтэй байна уу?`)
              if (confirmation) {
                await insertUpdateDeleteAccount({ accountNumber: rowData[1] })
              }
            })
          }
        }

        td.style.color = '#828282'
        td.classList.add('htCenter', 'htMiddle')
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        columns: [
          {
            title: ' ',
            data: '1',
            width: 25,
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Дансны код',
            data: 'account_number',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Дансны нэр',
            data: 'account_name',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Дансны ангилал',
            data: 'account_category',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Дансны тайлбар',
            data: 'account_description',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Дансны төрөл',
            data: 'account_type',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Эхний үлдэгдэл',
            data: 'transaction_amount',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
          {
            title: 'Засах / Устгах',
            data: 'btn',
            readOnly: true,
            renderer: safeHtmlRenderer,
          },
        ],
        colWidths: (index) => ([1, 5, 6, 7].includes(index) ? 55 : 120),
      }

      const data = computed(() => store.getters['bowsys/getAccountList'])

      onMounted(async () => {
        try {
          isLoading.value = true
          categoryList.value = await store.dispatch('bowsys/fetchAccountCategory')
          const message = await store.dispatch('bowsys/fetchAllAccounts', { currentPage: 1 })

          if (message === 'successfully') isLoading.value = false
        } catch (e) {
          isLoading.value = false
          console.error(e)
          throw e
        }
      })

      watch(data, (newVal, oldVal) => {
        if (newVal !== oldVal && hotTableRef.value) {
          hotTableRef.value.hotInstance.loadData(newVal.list)
        }
      })

      const handlePage = async (page) => {
        try {
          // isLoading.value = true
          // const message =
          await store.dispatch('bowsys/fetchAllAccounts', { currentPage: page })
          // if (message === 'successfully') isLoading.value = false
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      const insertUpdateDeleteAccount = async (dataToSend) => {
        try {
          const res = await store.dispatch('bowsys/insertUpdateDeleteAccount', dataToSend)
          if (res.message) {
            message.text = res.message
            message.isVisible = true

            await store.dispatch('bowsys/fetchAllAccounts', { currentPage: data.value.currentPage })

            setTimeout(() => {
              message.isVisible = false
            }, 3000)
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      return {
        saveNewAccount,
        isModalOpen,
        isDropdownOpen,
        isValue,
        selectOption,
        hotSettings,
        hotTableRef,
        isLoading,
        categoryList,
        message,
        title,
        handleSaveBtn,
        data,
        handlePage,
        formattedAmount,
      }
    },
  }
</script>

<style>
  .custom-handsontable .ht_clone_top th {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    background-color: #fcfcfc !important;
    color: #828282 !important;
  }

  .custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table td,
  .custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table th {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  .custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader .htBorders div .wtBorder {
    background-color: inherit !important;
  }

  .custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table td {
    border-top: 1px solid #f3f4f5 !important;
  }
</style>
