<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <hot-table v-if="balanceList?.length > 0" :data="balanceList" :settings="hotSettings" ref="hotRef" class="custom mt-6"></hot-table>
      <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'

  registerAllModules()

  export default {
    name: 'BalancePage',
    components: { BowsysNavbar, HotTable, SpinnerCom },

    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const hotRef = ref(null)

      /*
       **************************************** SHOW JOURNAL LIST ****************************************
       */

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = '#828282'

        td.innerHTML = value

        if (col >= 2 && col <= 8) {
          const numericValue = parseFloat(value)

          if (value === null || value === '' || isNaN(numericValue) || numericValue === 0) {
            td.innerHTML = '-'
          } else if (numericValue > 0) {
            const isWholeNumber = numericValue % 1 === 0

            const formattedValue = isWholeNumber
              ? numericValue.toLocaleString(undefined)
              : numericValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })

            td.innerHTML = formattedValue
          }
        }
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        nestedHeaders: [
          [
            { label: 'Дансны код', rowspan: 2 },
            { label: 'Дансны нэр', rowspan: 2 },
            { label: 'Эхний үлдэгдэл', colspan: 2 },
            { label: 'Гүйлгээ /өссөн дүнгээр/', colspan: 2 },
            { label: 'Эцсийн үлдэгдэл', colspan: 2 },
          ],
          ['', '', 'Дебет', 'Кредит', 'Дебет', 'Кредит', 'Дебет', 'Кредит'],
        ],
        columns: [
          { data: 'account_number', readOnly: true, renderer: commonRenderer },
          { data: 'account_name', readOnly: true, renderer: commonRenderer },
          { data: 'debit_initial', readOnly: true, renderer: commonRenderer },
          { data: 'credit_initial', readOnly: true, renderer: commonRenderer },
          { data: 'total_transaction_debit', readOnly: true, renderer: commonRenderer },
          { data: 'total_transaction_credit', readOnly: true, renderer: commonRenderer },
          { data: 'total_debit_last', readOnly: true, renderer: commonRenderer },
          { data: 'total_credit_last', readOnly: true, renderer: commonRenderer },
        ],
        afterRender: function () {
          const hotInstance = this
          const lastRowIndex = hotInstance.countRows() - 1
          if (lastRowIndex >= 0) {
            for (let col = 0; col < hotInstance.countCols(); col++) {
              hotInstance.getCell(lastRowIndex, col).style.fontWeight = 'bold'
            }
          }
        },
        colWidths: (index) => ([0].includes(index) ? 50 : index == 1 ? 200 : 120),
      }

      const balanceList = computed(() => store.getters['bowsys/getBalance'])

      onMounted(async () => {
        try {
          isLoading.value = true
          const message = await store.dispatch('bowsys/fetchBalanceList')

          if (message === 'successfully') {
            isLoading.value = false
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(balanceList, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal)
        }
      })

      return { hotSettings, balanceList, isLoading }
    },
  }
</script>
