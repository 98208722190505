import { instance } from '../lib/instance'

export default {
  namespaced: true,
  state() {
    return {
      route: JSON.parse(localStorage.getItem('bowsysRoute')) || [],
      accountList: [],
      salaryList: [],
      statisticList: [],
      accountNumberList: [],
      balanceList: [],
      balanceSheet: [],
      incomeStatement: [],
      cashFlow: [],
      ownerEquity: [],
    }
  },

  getters: {
    getRoute(state) {
      return state.route
    },
    getAccountList(state) {
      return state.accountList
    },
    getSalaryList(state) {
      return state.salaryList
    },
    getStatisticList(state) {
      return state.statisticList
    },
    getAccountNumberList(state) {
      return state.accountNumberList
    },
    getBalance(state) {
      return state.balanceList
    },
    getBalanceSheet(state) {
      return state.balanceSheet
    },
    getIncomeStatement(state) {
      return state.incomeStatement
    },
    getCashFlow(state) {
      return state.cashFlow
    },
    getOwnerEquity(state) {
      return state.ownerEquity
    },
  },

  mutations: {
    setRoute(state, payload) {
      state.route = payload
      localStorage.setItem('bowsysRoute', JSON.stringify(payload))
    },
    setAccountList(state, payload) {
      state.accountList = payload
    },
    setSalaryList(state, payload) {
      state.salaryList = payload
    },
    setStatisticList(state, payload) {
      state.statisticList = payload
    },
    setAccountNumberList(state, payload) {
      state.accountNumberList = payload
    },
    setBalance(state, payload) {
      state.balanceList = payload
    },
    setBalanceSheet(state, payload) {
      state.balanceSheet = payload
    },
    setIncomeStatement(state, payload) {
      state.incomeStatement = payload
    },
    setCashFlow(state, payload) {
      state.cashFlow = payload
    },
    setOwnerEquity(state, payload) {
      state.ownerEquity = payload
    },
  },

  actions: {
    async fetchRoute(context, payload) {
      try {
        context.commit('setRoute', payload)
      } catch (error) {
        console.error(error)
      }
    },

    // ACOUNTS
    async fetchAccountCategory() {
      try {
        const res = await instance.get('/accountCategory')
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    async fetchAllAccounts(context, payload) {
      try {
        const res = await instance.post('/allAccount', payload)
        context.commit('setAccountList', res.data)

        return res.data.message
      } catch (error) {
        console.error(error)
      }
    },

    async insertUpdateDeleteAccount(_, payload) {
      try {
        const res = await instance.post('/insertUpdateDeleteAccount', payload)
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    async fetchAccountList(context) {
      try {
        const res = await instance.post('/getAccountList')
        context.commit('setAccountNumberList', res.data.result.data)
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    // SALARY
    async fetchEmployeeList() {
      try {
        const res = await instance.post('/employeeList')
        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async insertUpdateDeleteEmployee(_, payload) {
      try {
        const res = await instance.post('/insertUpdateDeleteEmployee', payload)
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    async fetchSalaryList(context, payload) {
      try {
        const res = await instance.post('/salaryList', payload)
        context.commit('setSalaryList', res.data)

        return res.data.message
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async calculateSalary(_, payload) {
      try {
        const res = await instance.post('/calculateSalary', payload)
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    async fetchStatistics(context, payload) {
      try {
        const res = await instance.post('/salaryStatistics', payload)
        context.commit('setStatisticList', res.data)

        return res.data.message
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },
    // JOURNEL
    async fetchJournalList() {
      try {
        const res = await instance.post('/journalList')
        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async addNewJournals(_, payload) {
      try {
        const res = await instance.post('/addNewJournals', payload)
        return res.data
      } catch (error) {
        console.error(error)
      }
    },

    async fetchBalanceList(context) {
      try {
        const res = await instance.post('/balanceList')
        context.commit('setBalance', res.data.data)

        return res.data.message
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // STATEMENTS
    async fetchBalanceSheet(context) {
      try {
        const res = await instance.post('/balanceSheet')
        context.commit('setBalanceSheet', res.data.data)

        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async fetchIncomeStatement(context) {
      try {
        const res = await instance.post('/incomeStatement')
        context.commit('setIncomeStatement', res.data.data)

        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async fetchCashFlow(context) {
      try {
        const res = await instance.post('/cashFlow')
        context.commit('setCashFlow', res.data.data)

        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    async fetchOwnerEquity(context) {
      try {
        const res = await instance.post('/ownerEquity')
        context.commit('setOwnerEquity', res.data.data)

        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },
  },
}
