<template>
  <div id="app" class="mb-10">
    <ul class="flex justify-center items-center">
      <li v-if="currentPage > 1" class="px-2 py-1.5 text-[#B7B7B7] bg-[#EAEAEA] rounded-sm cursor-pointer">
        <a @click="changePage(currentPage - 1)">
          <font-awesome-icon icon="fa-chevron-left" class="h-4 w-4" />
        </a>
      </li>

      <li v-if="currentPage > 2" class="text-base px-5 font-medium text-gray-500 cursor-pointer">
        <a @click="changePage(1)">1</a>
      </li>

      <li v-if="currentPage > 3" class="text-base px-5 font-medium text-gray-500">...</li>

      <li
        v-for="page in displayedPages"
        :key="page"
        class="cursor-pointer text-base font-medium mx-5"
        :class="page === currentPage ? 'text-white bg-blue-500 px-3 py-0.5 rounded-sm' : 'text-gray-500 '"
      >
        <a @click="changePage(page)">
          {{ page }}
        </a>
      </li>

      <li
        v-if="totalPages - displayedPages[displayedPages.length - 1] !== 1 && displayedPages[displayedPages.length - 1] !== totalPages"
        class="text-base px-5 font-medium text-gray-500"
      >
        ...
      </li>

      <li v-if="displayedPages[displayedPages.length - 1] !== totalPages" class="text-base px-5 font-medium text-gray-500 cursor-pointer">
        <a @click="changePage(totalPages)">
          {{ totalPages }}
        </a>
      </li>

      <li v-if="currentPage < totalPages" class="px-2 py-1.5 text-[#B7B7B7] bg-[#EAEAEA] rounded-sm cursor-pointer">
        <a @click="changePage(currentPage + 1)">
          <font-awesome-icon icon="fa-chevron-right" class="h-4 w-4" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { ref, computed, onMounted, onUpdated } from 'vue'

  export default {
    name: 'PaginationCart',
    props: {
      totalListPages: Number,
    },
    setup(props, { emit }) {
      const currentPage = ref(1)
      const itemsPerPage = 20
      const totalPages = ref(0)

      const displayedPages = computed(() => {
        const pages = []
        let startPage = 1
        let endPage = totalPages.value
        const maxDisplayedPages = 3

        if (totalPages.value > maxDisplayedPages) {
          const middlePage = Math.ceil(maxDisplayedPages / 2)
          startPage = currentPage.value - middlePage + 1
          endPage = currentPage.value + middlePage - 1

          if (startPage < 1) {
            endPage += Math.abs(startPage) + 1
            startPage = 1
          }

          if (endPage > totalPages.value) {
            startPage -= endPage - totalPages.value
            endPage = totalPages.value
          }
        }

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i)
        }
        return pages
      })

      const changePage = (page) => {
        currentPage.value = page

        emit('data-incomplete', currentPage.value)
        emit('sent-completed', currentPage.value)
        emit('sent-users', currentPage.value)
      }

      const updatePages = () => {
        totalPages.value = props.totalListPages
      }

      onMounted(updatePages)
      onUpdated(updatePages)

      return {
        currentPage,
        itemsPerPage,
        totalPages,
        displayedPages,
        changePage,
      }
    },
  }
</script>
